<template>
  <div class="withdraw">
    <!-- <Header
      title="银行卡账号"
      :rightTitle="isEdit ? '取消' : '编辑'"
      @rightFuc="isEdit = !isEdit"
    /> -->
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">我的银行卡</div>
      <div class="rightBtn" @click="isEdit = !isEdit">
        {{ isEdit ? "保存" : "编辑" }}
      </div>
      <!-- <router-link tag="div" to="withdrawalDetails" class="rightBtn"
        >提现明细</router-link
      > -->
    </div>
    <div class="main">
      <div
        class="bank-card-cell"
        v-for="item in bankCards"
        :key="item.id"
        @click="selectCard(item)"
      >
        <div class="bank-info-box">
          <div class="bank-info">
            <div class="name">
              {{ getBankName(item.bankCode) }}
              <span>{{ item.actName }}</span>
            </div>
            <div class="bankNo">储蓄卡：{{ item.act }}</div>
          </div>
        </div>
        <div v-if="!isEdit">
          <div class="bank_selected" v-if="item.id == currentItem.id"></div>
          <div class="bank_unselect" v-else></div>
        </div>
        <div @click.stop="openDelDialog(item.id)" v-else>
          <svg-icon iconClass="bankDelIcon" class="delBtn"></svg-icon>
        </div>
      </div>
      <div class="add-bank-card-cell" @click="showAdd = true">
        <div class="add">+</div>
        添加银行卡
      </div>
    </div>
    <van-popup v-model="showAdd" @click-overlay="close">
      <div class="add-bankcard-popup">
        <div class="title">添加银行卡</div>
        <div class="line"></div>
        <div class="popupContent">
          <van-field
            class="input-info"
            v-model="bankCardNumber"
            placeholder="请输入银行卡号"
            :border="false"
            @blur="changBlur"
          />
          <van-field
            class="input-info"
            v-model="bankCardName"
            placeholder="请输入持卡人姓名"
            :border="false"
            @blur="changBlur"
          />
        </div>

        <div class="addBtnBox">
          <div class="addClose" @click="close">取消</div>
          <div class="addConfirm" @click="confirm">确定添加</div>
        </div>
      </div>
    </van-popup>
    <van-overlay :show="showDelDialog" z-index="9999">
      <div class="wrapper">
        <div class="delBox">
          <div class="delTitle">您是否移除该银行卡?</div>
          <div class="btnBox">
            <div class="cancel" @click.stop="handleClose">取消</div>
            <div class="confirm" @click.stop="confirmDel">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getBank } from "@/utils/bankCardList";
import { mapGetters } from "vuex";
import {
  queryBankCards,
  bindBankCard,
  delBankCard,
  checkBankCard,
} from "@/api/mine";
import {
  Field,
  // Loading,
  RadioGroup,
  Radio,
  Toast,
} from "vant";
export default {
  name: "bankCardList",
  components: {
    [Field.name]: Field,
    // [Loading.name]: Loading,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  computed: {
    ...mapGetters({
      bankCard: "bankCard",
    }),
  },
  data() {
    return {
      bankCards: [],
      showAdd: false,
      bankCardNumber: "",
      bankCardName: "",
      loading: false,
      currentItem: {}, // 当前选中的银行卡
      bankDatas: getBank(), // 银行列表
      isEdit: false, // 编辑
      showDelDialog: false, // 打开删除窗口
      delId: "", // 删除的账号ID
    };
  },
  created() {
    if (this.bankCard.act) {
      this.currentItem = this.bankCard;
    }
    this.getBankCards();
  },
  methods: {
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    async getBankCards() {
      try {
        this.$store.commit("app/SET_LOADING", true);
        let res = await this.$Api(queryBankCards);
        // console.log(res)
        this.$store.commit("app/SET_LOADING", false);
        if (res && res.code == 200) {
          this.bankCards = res.data.list;
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("查询失败，请重新进入");
      }
    },
    getBankName(bankCode) {
      let str = "";
      this.bankDatas.forEach((i) => {
        if (i.value == bankCode) {
          str = i.text;
        }
      });
      return str;
    },
    async confirm() {
      console.log(123213);
      if (!this.bankCardNumber) {
        Toast("请输入银行卡号");
        return;
      }
      if (!this.bankCardName) {
        Toast("请输入持卡人姓名");
        return;
      }
      let req = {
        _input_charset: "utf-8",
        cardBinCheck: true,
        cardNo: this.bankCardNumber.split(" ").join(""),
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(checkBankCard, req);
        // this.$store.commit("app/SET_LOADING", false);
        if (res && res.validated && res.bank && res.cardType) {
          this.showAdd = false;
          let data = {
            actName: this.bankCardName,
            act: res.key,
            bankCode: res.bank,
            cardType: res.cardType,
          };
          this.bindBankCard(data);
        } else {
          Toast("请输入正确的银行卡号");
          this.$store.commit("app/SET_LOADING", false);
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("网络错误，请稍后再试");
      }
    },
    async bindBankCard(queryData) {
      try {
        let req = queryData;
        await this.$Api(bindBankCard, req);
        this.$store.commit("app/SET_LOADING", false);
        this.bankCardName = "";
        this.bankCardNumber = "";
        Toast("添加成功");
        this.getBankCards();
      } catch (e) {
        this.$store.commit("app/SET_LOADING", false);
        console.log(e);
      }
    },
    async confirmDel() {
      if (!this.delId) return Toast("请选择银行卡");
      const delId = this.delId;
      try {
        let req = {
          id: this.delId,
        };
        await this.$Api(delBankCard, req);
        if (delId == this.bankCard.id) {
          this.$store.commit("app/SET_BANKCARD", {
            id: "",
            actName: "",
            act: "",
            bankCode: "",
            cardType: "",
          });
          this.delId = "";
          this.showDelDialog = false;
        }
        Toast("删除成功");
        this.getBankCards();
      } catch (e) {
        console.log(e);
      }
    },
    selectCard(item) {
      this.currentItem = item;
      this.$store.commit("app/SET_BANKCARD", item);
      this.$router.go(-1);
    },
    openDelDialog(id) {
      this.delId = id;
      this.showDelDialog = true;
    },
    handleClose() {
      this.delId = "";
      this.showDelDialog = false;
    },
    close() {
      this.showAdd = false;
      this.bankCardNumber = "";
      this.bankCardName = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.withdraw {
  background: #f5f5f5;
  height: 100%;
  .navBar {
    height: 44px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 47px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 64px;
      font-size: 15px;
      text-align: right;
      color: #999999;
    }
  }
  .main {
    height: calc(100% - 45px);
    overflow-y: auto;
    padding: 12px 16px 0;
    box-sizing: border-box;
    .bank-card-cell {
      margin-bottom: 20px;
      color: #000;
      background-color: rgb(255, 255, 255);
      padding: 17px 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bank-info-box {
        display: flex;
        align-items: center;
        justify-content: start;
        .bank-info {
          margin-left: 12px;
          .name {
            font-size: 16px;
            font-weight: 500;
            color: #333;
            // color: white;
            span {
              font-size: 10px;
              font-weight: 400;
              color: #666;
            }
          }
        }
        .bankNo {
          font-size: 12px;
          color: #666;
          margin-top: 10px;
          font-weight: 400;
        }
      }
      .bank_unselect {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 1px solid #666;
      }
      .bank_selected {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #94d6da;
      }
      .delBtn {
        height: 18px;
        width: 18px;
      }
    }
    .add-bank-card-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 77px;
      border-radius: 4px;
      font-size: 16px;
      border: 1px dashed rgb(154, 154, 154);
      .add {
        font-size: 26px;
        margin-bottom: 5px;
      }
    }
  }
  .add-bankcard-popup {
    // height: 288px;
    width: 300px;
    background: linear-gradient(180deg, #e5feff 0%, #fff 100%);
    // background-color: rgb(38, 38, 38);
    // background: url('./../../../../assets/images/png/addBankBg.png') no-repeat;
    // background-size: 100% 100%;
    border-radius: 11px;
    overflow: hidden;
    // padding: 22px 15px;
    .title {
      font-size: 18px;
      text-align: center;
      margin: 24px 0 18px;
    }
    .line {
      // margin-bottom: 30px;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(230, 230, 230, 0) 0%,
        #e5e5e5 47.92%,
        rgba(230, 230, 230, 0) 100%
      );
    }
    .popupContent {
      padding: 18px 22px;
      box-sizing: border-box;
    }
    .addBtnBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 22px;
      box-sizing: border-box;
      margin-bottom: 27px;
      // margin-top: 30px;
      .addClose {
        height: 38px;
        width: 108px;
        border-radius: 38px;
        color: #fff;
        background-color: #b2b2b2;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
      }
      .addConfirm {
        height: 38px;
        width: 136px;
        border-radius: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #94d6da;
        // background: url('./../../../../assets/images/png/nowAdd.png') no-repeat;
        // background-size: 100% 100%;
      }
    }
  }
}
/deep/ .van-popup {
  background-color: rgba(0, 0, 0, 0) !important;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .delBox {
    height: 123px;
    width: 266px;
    background: #fff;
    border-radius: 8px;
    // background: url("./../../../../assets/images/png/removeBg.png") no-repeat;
    // background-size: 100% 100%;
    padding: 25px 45px;
    box-sizing: border-box;
    .delTitle {
      font-size: 18px;
    }
    .btnBox {
      font-size: 15px;
      margin-top: 30px;
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cancel {
        color: rgb(205, 205, 205);
      }
    }
  }
}
/deep/ .van-cell {
  // background: transparent;
  // border: 1px solid rgba(215, 215, 215, 1);
  // border-radius: 6px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);
  height: 40px;
  padding: 8px 20px;
  font-size: 12px;
  margin-bottom: 20px;
  // .van-field__control {
  //   color: white;
  // }
  ::placeholder {
    color: #999;
  }
}
/deep/ .van-cell:last-child {
  margin-bottom: 0;
}
</style>
